const locale = {
  contactInfoDialog:
    'En caso de dudas contacta a la Mesa de Atención a Clientes, de lunes a viernes de 9:00 a 18:30 horas. Teléfono +56 22 3939001 o correo: ',
  errorDialog: 'Ocurrió un error. Intenta nuevamente',
  successDialog: 'Envío de solicitud de registro exitoso',
  successInfoDialog: 'Modificación registrada con éxito',
  successInfoDialogAnnulment: 'Contrato anulado',
  requestSentSuccessfully: 'Solicitud enviada exitosamente ',
  infoUpdatedSuccessfully: 'Información actualizada con éxito',
  requestEnteredSuccessfully: 'Solicitud ingresada exitosamente',
  validationDialog:
    'Estamos validando la información, proximamente podrás ver el estado de la solicitud en ',
  reports: 'Reportes',
  successInfoTextAnnulment:
    'Se ha grabado exitosamente la solicitud de anulación descrita anteriormente',
  confirmationDialog: '¿Estas seguro que quieres enviar la solicitud?',
  confirmationDialogModify: 'Confirmar modificación',
  confirmationDialogAnnulment: 'Confirmar anulación',
  confirmRequest: 'Confirmar solicitud',
  confirmContractRegistration:
    '¿Estás seguro que quieres registrar el contrato?',
  confirmationDialogParticipants:
    '¿Estás seguro que quieres crear la relación?',
  confirmationDialogWarning: 'No podrás deshacer esta acción.',
  forwardContractDuplicateRequestSent:
    'Se ha enviado exitosamente la solicitud de duplicado del contrato de Forward',
  confirmSubscription: 'Confirmar suscripción',
  confirmAdvance: 'Confirmar anticipo',
  confirmModification: 'Confirmar modificación',
  confirmCancellation: 'Confirmar cancelación',
  confirmAdvanceSubscription: 'Confirmar suscripción de anticipo',
  confirmModificationSubscription: 'Confirmar suscripción de modificación',
  confirmModAuthorization: 'Confirmar autorización de modificación',
  confirmAuthorization: 'Confirmar autorización',
  confirmRegistration: 'Confirmar Registro',
  confirmCanAuthorization: 'Confirmar autorización de anulación',
  confirmSubAuthorization: 'Confirmar autorización de suscripción',
  confirmButton: 'Sí, enviar',
  cancelButton: 'No, volver',
  confirm: 'Confirmar',
  understood: 'Entendido',
  continue: 'Continuar',
  back: 'Volver',
  accept: 'Sí, Aceptar',
  noCancel: 'No, Cancelar',
  retryButton: 'Intentar nuevamente',
  acceptButton: 'Aceptar',
  enterCode: 'Ingresar el código de seguridad',
  checkCode: 'Revisa el código que aparece en tu celular.',
  wrongCode: 'Código incorrecto',
  errorCode: 'Hubo un error. Intenta de nuevo',
  successfulDownload: 'Descarga exitosa',
  confirmSaveChanges: '¿Estás seguro que quieres guardar los cambios?',
  yesSaveChanges: 'Sí, guardar cambios',
  cancel: 'Cancelar',
  confirmDeactivation: '¿Estás seguro que quieres desactivar el contrato?',
  contractPendingApprovalForDeactivation:
    'El contrato está pendiente de aprobación para ser desactivado',
  expirationDateModifiedToToday:
    'Se ha modificado la fecha de vencimiento al día de hoy',
  activeOrPendingContractExistsBetween:
    'Existe contrato activo o pendiente entre: {{code1}} y {{code2}}.',
  alreadySubscribedToContracts:
    'Ya has suscrito uno o más contratos de la selección',
  removeContractsFromListToContinue:
    'Quita de la lista el(los) contratos(s), para continuar con el proceso de suscripción:',
  contractsSubscribed: 'Has suscrito el(los) contrato(s)',
  checkProcessStatusLater: 'Verifique más tarde el estado del proceso',
  notModifiedTitle: 'No se han realizado cambios.',
  notModifiedText:
    'Por favor, revise y modifique si es necesario para continuar',
  contractsCouldNotBeSubscribed:
    'Los siguientes contratos no pudieron ser suscritos:',
  anticipateSuccessfullyRegistered: 'Anticipo registrado con éxito',
  advanceRequestSuccessfullyRegistered:
    'Solicitud de anticipo registrada con éxito',
  advanceSubscriptionSuccessfullyRegistered:
    'Suscripción de anticipo registrada con éxito',
  authorizationSuccessfullyRegistered: 'Autorización registrada con éxito',
  modificationRequestSuccess: 'Solicitud de modificación registrada con éxito',
  modSubSuccess: 'Suscripción de modificación registrada con éxito',
  modAuthSuccess: 'Autorización de modificación registrada con éxito',
  canAuthSuccess: 'Autorización de anulación registrada con éxito',
  requestCancelled: 'Solicitud cancelada',
};

export default locale;
