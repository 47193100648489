const locale = {
  TITLE: 'Annulment',
  home: 'Home',
  search: 'Search',
  annulment: 'Annulment',
  pagerInfo: '{{currentPage}} of {{totalPages}}',
  contract: 'Contract',
  viewContract: 'View contract',
  back: 'Back',
};

export default locale;
