const enLocale = {
  WELCOME_TYPE_1: 'Welcome to the',
  WELCOME_TYPE_2: 'Forward Service',
  ENTER_DATA: 'Enter the following data',
  RUT: 'RUT',
  USER: 'User',
  PASSWORD: 'Password',
  TOKEN: 'Token',
  PARTICIPANT_CODE: 'Participant Code',
  LOGIN: 'Login',
  ERROR_NOTIFICATION:
    'Invalid username, password and/or token. Contact your security administrator if the problem persists.',
  INFO_NOTIFICATION:
    'If you have any questions, please contact the Customer Service Desk, Monday to Friday from 9:00 AM to 6:30 PM. Phone +56 22 3939001 or email: mac@dcv.cl',
};

export default enLocale;
