const locale = {
  contactInfoDialog:
    'For any questions, please contact the Customer Service Desk, Monday through Friday from 9:00 a.m. to 6:30 p.m. Phone +56 22 3939001 or email: ',
  errorDialog: 'An error occurred. Please try again',
  successDialog: 'Successful registration request submission.',
  successInfoDialog: 'Modification registered successfully',
  successInfoDialogAnnulment: 'Contract voided',
  requestSentSuccessfully: 'Request Sent Successfully',
  infoUpdatedSuccessfully: 'Information successfully updated',
  requestEnteredSuccessfully: 'Request successfully submitted',
  validationDialog:
    'We are validating the information, soon you will be able to see the status of the request in ',
  reports: 'Reports',
  successInfoTextAnnulment:
    'The cancellation request described above has been successfully saved',
  confirmationDialog: 'Are you sure you want to proceed?',
  confirmationDialogModify: 'Confirm modification',
  confirmationDialogAnnulment: 'Confirm cancellation',
  confirmRequest: 'Confirm Request',
  confirmContractRegistration:
    'Are you sure you want to register the contract?',
  confirmationDialogParticipants:
    'Are you sure you want to create the relation?',
  confirmationDialogWarning: 'This action cannot be undone.',
  forwardContractDuplicateRequestSent:
    'The duplicate request for the Forward contract has been sent successfully',
  forwardContractModifyRequestSent: 'Modification registered successfully',
  confirmSubscription: 'Confirm Subscription',
  confirmAdvance: 'Confirm Advance',
  confirmModification: 'Confirm Modification',
  confirmCancellation: 'Confirm Cancellation',
  confirmAdvanceSubscription: 'Confirm Advance Subscription',
  confirmModificationSubscription: 'Confirm Modification Subscription',
  confirmModAuthorization: 'Confirm Modification Authorization',
  confirmAuthorization: 'Confirm Authorization',
  confirmRegistration: 'Confirm Registration',
  confirmCanAuthorization: 'Confirm Cancellation Authorization',
  confirmSubAuthorization: 'Confirm Subscription Authorization',
  confirmButton: 'Yes, proceed',
  cancelButton: 'No, go back',
  accept: 'Yes, Accept',
  noCancel: 'No, Cancel',
  confirm: 'Confirm',
  understood: 'Understood',
  continue: 'Continue',
  back: 'Back',
  retryButton: 'Try again',
  acceptButton: 'Accept',
  enterCode: 'Enter the security code',
  checkCode: 'Check the code that appears on your cell phone.',
  wrongCode: 'Wrong code',
  errorCode: 'There was an error. Please try again',
  successfulDownload: 'Successful download',
  confirmSaveChanges: 'Are you sure you want to save the changes?',
  yesSaveChanges: 'Yes, save changes',
  cancel: 'Cancel',
  confirmDeactivation: 'Are you sure you want to deactivate the contract?',
  contractPendingApprovalForDeactivation:
    'The contract is pending approval for deactivation',
  expirationDateModifiedToToday:
    'The expiration date has been modified to today',
  activeOrPendingContractExistsBetween:
    'An active or pending contract exists between: {{code1}} and {{code2}}.',
  alreadySubscribedToContracts:
    'You have already subscribed to one or more contracts from the selection',
  removeContractsFromListToContinue:
    'Remove the contract(s) from the list to continue with the subscription process:',
  contractsSubscribed: 'You have subscribed to the contract(s)',
  checkProcessStatusLater: 'Check the process status later',
  notModifiedTitle: 'No changes have been made.',
  notModifiedText: 'Please review and modify if necessary to continue',
  contractsCouldNotBeSubscribed:
    'The following contracts could not be subscribed:',
  anticipateSuccessfullyRegistered: 'Anticipate successfully registered',
  advanceRequestSuccessfullyRegistered:
    'Advance request successfully registered',
  modificationRequestSuccess: 'Modification request successfully registered',
  advanceSubscriptionSuccessfullyRegistered:
    'Advance subscription successfully registered',
  authorizationSuccessfullyRegistered: 'Authorization successfully registered',
  modSubSuccess: 'Modification subscription successfully registered',
  modAuthSuccess: 'Modification authorization successfully registered',
  canAuthSuccess: 'Cancellation authorization successfully registered',
  requestCancelled: 'Request Cancelled',
};

export default locale;
