import _ from '@lodash';
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useCallback, useEffect, useRef, useState } from 'react';
import appConfig from 'src/app/appConfig';
import apiNuamClient from 'src/app/clients/nuamApiClient';
import { PartialDeep } from 'type-fest';
import UserModel from '../../user/models/UserModel';
import apiAuthClient from './authApiClient';

const defaultAuthConfig = {
  tokenStorageKey: 'jwt_access_token',
  tokenRefreshKey: 'jwt_refresh_key',
  signInUrl: 'api/auth/sign-in',
  dataUser: 'user_data',
  tokenRefreshUrl: 'api/auth/refresh',
  getUserUrl: 'api/auth/user',
  updateUserUrl: 'api/auth/user',
  updateTokenFromHeader: false,
};

type AuthResponse = {
  email: string;
  email_verified: boolean;
  firstname: string;
  lastname: string;
  name: string;
  preferred_username: string;
  sub: string;
  roles: string;
  participantCode: string;
  participantName: string;
  poa: string;
  poaroles: string;
};

interface Credentials {
  username: string;
  password: string;
  company: string;
  token: string;
}

export type JwtAuthProps<T> = {
  config: {
    tokenStorageKey: string;
    signInUrl: string;
    tokenRefreshUrl: string;
    tokenRefreshKey: string;
    dataUser: string;
    getUserUrl: string;
    updateUserUrl: string;
    /**
     * If the response auth header contains a new access token, update the token
     * in the Authorization header of the successful responses
     */
    updateTokenFromHeader: boolean;
  };
  onSignedIn?: (U: T) => void;
  onSignedOut?: () => void;
  onUpdateUser?: (U: T) => void;
  onError?: (error: AxiosError) => void;
};

export type PowerOfAttorney = {
  code: string;
  name: string;
};

export type JwtAuth<User, SignInPayload> = {
  user: User;
  isAuthenticated: boolean;
  isLoading: boolean;
  signIn: (U: SignInPayload) => Promise<AxiosResponse<User, AxiosError>>;
  signOut: () => void;
  updateUser: (U: PartialDeep<User>) => void;
  refreshToken: () => void;
  setIsLoading: (isLoading: boolean) => void;
  isTokenValid: (token: string) => boolean;
};

/**
 * useJwtAuth hook
 * Description: This hook handles the authentication flow using JWT
 * It uses axios to make the HTTP requests
 * It uses jwt-decode to decode the access token
 * It uses localStorage to store the access token
 * It uses Axios interceptors to update the access token from the response headers
 * It uses Axios interceptors to sign out the user if the refresh token is invalid or expired
 */

export const useJwtAuth = <User, SignInPayload extends Credentials>(
  props: JwtAuthProps<User>,
): JwtAuth<User, SignInPayload> => {
  const { config, onSignedIn, onSignedOut, onError, onUpdateUser } = props;
  const authConfig = _.defaults(config, defaultAuthConfig);

  const [user, setUser] = useState<User>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * Set session
   */
  const setSession = useCallback(
    (accessToken: string, refreshToken: string, userData: User) => {
      if (accessToken) {
        localStorage.setItem(authConfig.tokenStorageKey, accessToken);
        localStorage.setItem(authConfig.tokenRefreshKey, refreshToken);
        localStorage.setItem(authConfig.dataUser, JSON.stringify(userData));
        apiAuthClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        apiNuamClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        setTimeOutRefreshToken(refreshToken);
      }
    },
    [],
  );

  const resetSession = useCallback(() => {
    localStorage.removeItem(authConfig.tokenStorageKey);
    localStorage.removeItem(authConfig.tokenRefreshKey);
    localStorage.removeItem(authConfig.dataUser);
    delete apiAuthClient.defaults.headers.common.Authorization;
    delete apiNuamClient.defaults.headers.common.Authorization;
    apiAuthClient.interceptors.request.clear();
    apiNuamClient.interceptors.request.clear();
  }, []);

  /**
   * Get access token from local storage
   */
  const getAccessToken = useCallback(() => {
    return localStorage.getItem(authConfig.tokenStorageKey);
  }, []);

  const getRefreshToken = useCallback(() => {
    return localStorage.getItem(authConfig.tokenRefreshKey);
  }, []);

  const getUserData = useCallback(() => {
    return JSON.parse(localStorage.getItem(authConfig.dataUser)) as User;
  }, []);

  const getUserRole = (userRole: string): string | null => {
    const arrUserRole = userRole.split(';');
    return arrUserRole[0]?.trim() || null;
  };

  const setTimeOutRefreshToken = (refreshToken: string) => {
    const { exp } = jwtDecode(refreshToken) as { exp: number };
    const timeExp = exp * 1000 - Date.now();
    if (timeExp > 0) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        signOut();
      }, timeExp);
    }
  };

  const pluckUserRut = (userRut: string): string => {
    const arrUserRut = userRut.split('-');
    if (arrUserRut.length > 1) {
      arrUserRut.pop();
    }
    return arrUserRut.join('-');
  };

  const parsePowerOfAttorney = (poa: string): PowerOfAttorney[] => {
    return poa
      .split(';')
      .filter((p) => p)
      .map((entry) => {
        const [code, name] = entry.split(',');
        return { code: code.trim(), name: name.trim() };
      });
  };

  const handleRequest = async (
    config: InternalAxiosRequestConfig<unknown>,
    accessToken: string,
    _refreshToken: string,
    userData: User,
  ): Promise<InternalAxiosRequestConfig<unknown>> => {
    if (!isTokenValid(accessToken) && isTokenValid(_refreshToken)) {
      const { accessToken, _refreshToken } = (await refreshToken()) as {
        accessToken: string;
        _refreshToken: string;
      };
      setSession(accessToken, _refreshToken, userData);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  };

  /**
   * Handle sign-in success
   */
  const handleSignInSuccess = useCallback(
    (userData: User, accessToken: string, _refreshToken: string) => {
      apiAuthClient.interceptors.request.clear();
      apiNuamClient.interceptors.request.clear();
      apiAuthClient.interceptors.request.use((config) =>
        handleRequest(config, accessToken, _refreshToken, userData),
      );
      apiNuamClient.interceptors.request.use((config) =>
        handleRequest(config, accessToken, _refreshToken, userData),
      );

      setSession(accessToken, _refreshToken, userData);

      setIsAuthenticated(true);

      setUser(userData);

      onSignedIn(userData);
    },
    [],
  );

  /**
   * Handle sign-in failure
   */
  const handleSignInFailure = useCallback((error: AxiosError) => {
    resetSession();

    setIsAuthenticated(false);
    setUser(null);

    handleError(error);
  }, []);

  /**
   * Handle error
   */
  const handleError = useCallback((error: AxiosError) => {
    onError(error);
  }, []);

  /**
   * Check if the access token is valid
   */
  const isTokenValid = useCallback((token: string) => {
    if (token) {
      try {
        const decoded = jwtDecode<JwtPayload>(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp > currentTime;
      } catch (error) {
        return false;
      }
    }
    return false;
  }, []);

  /**
   * Check if the access token exist and is valid on mount
   * If it is, set the user and isAuthenticated states
   * If not, clear the session
   */
  useEffect(() => {
    const attemptAutoLogin = async () => {
      const accessToken = getAccessToken();
      const _refreshToken = getRefreshToken();
      const userData = getUserData();
      if (isTokenValid(accessToken)) {
        try {
          setIsLoading(true);
          handleSignInSuccess(userData, accessToken, _refreshToken);

          return true;
        } catch (error) {
          const axiosError = error as AxiosError;

          handleSignInFailure(axiosError);
          return false;
        }
      } else if (isTokenValid(_refreshToken)) {
        try {
          await refreshToken();

          return true;
        } catch (error) {
          const axiosError = error as AxiosError;

          handleSignInFailure(axiosError);
          return false;
        }
      } else {
        resetSession();
        return false;
      }
    };

    if (!isAuthenticated) {
      attemptAutoLogin().then(() => {
        setIsLoading(false);
      });
    }
  }, [
    isTokenValid,
    setSession,
    handleSignInSuccess,
    handleSignInFailure,
    handleError,
    getAccessToken,
    getRefreshToken,
    isAuthenticated,
  ]);

  /**
   * Sign in
   */

  const signIn: (
    credentials: SignInPayload,
  ) => Promise<AxiosResponse<User, AxiosError<unknown, unknown>>> = async (
    credentials,
  ) => {
    const response = await axios.post(
      `${appConfig.apiLoginUrl}/token`,
      credentials,
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      },
    );
    const accessToken = response?.data?.access_token as string | undefined;
    const _refreshToken = response?.data?.refresh_token as string | undefined;

    const newUser = await callUserData(accessToken);
    const roleUser = getUserRole(newUser.roles);
    const userRut = pluckUserRut(newUser.preferred_username);
    const poa = parsePowerOfAttorney(newUser.poa);

    if (newUser && roleUser) {
      const user = UserModel({
        uid: '',
        role: roleUser,
        data: {
          username: newUser.name,
          firstname: newUser.firstname,
          lastname: newUser.lastname,
          participantCode: newUser.participantCode,
          participantName: newUser.participantName,
          rut: userRut,
          email: newUser.email,
          shortcuts: [],
          settings: {},
          poa: poa,
          poaroles: newUser.poaroles,
        },
      }) as User;
      handleSignInSuccess(user, accessToken, _refreshToken);
    } else {
      throw new Error(`Credenciales incorrectas`);
    }
    return response;
  };

  /**
   * Sign out
   */
  const signOut = useCallback(() => {
    resetSession();
    setIsAuthenticated(false);
    setUser(null);
    onSignedOut();
  }, []);

  /**
   * Update user
   */
  const updateUser = async (userData: PartialDeep<User>) => {
    const response: AxiosResponse<User, PartialDeep<User>> = await axios.put(
      authConfig.updateUserUrl,
      userData,
    );
    const updatedUserData = response?.data;
    onUpdateUser(updatedUserData);
    return updatedUserData;
  };

  /**
   * Refresh access token
   */
  const refreshToken = async (): Promise<
    { accessToken: string; _refreshToken: string | null } | Error
  > => {
    try {
      const refreshKey = localStorage.getItem(authConfig.tokenRefreshKey);

      if (!refreshKey) {
        throw new Error(
          'No se encontró un token de actualización en el almacenamiento local.',
        );
      }

      const credentials = {
        grant_type: 'refresh_token',
        client_id: 'dcv-isv',
        refresh_token: refreshKey,
      };
      const response: AxiosResponse<{
        access_token: string;
        refresh_token: string;
      }> = await axios.post(`${appConfig.apiLoginUrl}/token`, credentials, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      });

      const accessToken = response?.data?.access_token;
      const _refreshToken = response?.data?.refresh_token;
      if (accessToken) {
        const newUser = getUserData();
        handleSignInSuccess(newUser, accessToken, _refreshToken);
        return { accessToken, _refreshToken };
      }
      throw new Error('Error desconocido al refrescar el token.');
    } catch (error) {
      handleError(error as AxiosError<unknown, unknown>);
      return error as AxiosError<unknown, unknown>;
    }
  };

  const callUserData = async (
    accessToken: string,
  ): Promise<AuthResponse | null> => {
    let response = await axios.post(`${appConfig.apiLoginUrl}/userinfo`, '', {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response?.data || null;
  };

  return {
    user,
    isAuthenticated,
    isLoading,
    signIn,
    signOut,
    updateUser,
    refreshToken,
    setIsLoading,
    isTokenValid,
  };
};

export default useJwtAuth;
