const locale = {
  TITLE: 'Anular',
  home: 'Inicio',
  search: 'Buscar',
  annulment: 'Anular',
  pagerInfo: '{{currentPage}} de {{totalPages}}',
  contract: 'Contrato',
  viewContract: 'Ver contrato',
  back: 'Volver',
};

export default locale;
