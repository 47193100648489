import { useState } from 'react';
import '../style/styles/MenuLateral.css';
import PendingOrders from '../style/assets/PendingOrders.svg';
import PendingOrdersActive from '../style/assets/PendingOrdersActive.svg';
import OrderForm from '../style/assets/OrderForm.svg';
import OrderFormActive from '../style/assets/OrderFormActive.svg';
import ArrowDown from '../style/assets/ArrowDown.svg';
import Arrow from '../style/assets/ArrowDown.svg';
import { useNavigate } from 'react-router-dom';
import Home from '../style/assets/home.svg';
import Register from '../style/assets/registerMovil.svg';
import Search from '../style/assets/search.svg';
import Country from '../style/assets/Counterparty.svg';
import Report from '../style/assets/report.svg';

import i18next from 'i18next';
import en from '../i18n/en';
import es from '../i18n/es';
import { useTranslation } from 'react-i18next';

i18next.addResourceBundle('en', 'side-menu', en);
i18next.addResourceBundle('es', 'side-menu', es);

const SideMenuCompany = ({ isCollapsed }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [showRegistrar, setShowRegistrar] = useState(false);
  const { t } = useTranslation('side-menu');
  const toggleRegistrar = () => setShowRegistrar(!showRegistrar);
  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === 'form') {
      navigate('/form-register');
    } else if (buttonName === 'home') {
      navigate('/home');
    } else if (buttonName === 'order') {
      navigate('/file-register');
    } else if (buttonName === 'search') {
      navigate('/search');
    } else if (buttonName === 'reports') {
      navigate('/reports');
    }
  };

  const getInitials = (text: string) => {
    const words = text.split(/\s/);
    const initials =
      words.length > 1
        ? words[0].charAt(0) + words[1].charAt(0)
        : words[0].charAt(0);
    return initials.toUpperCase();
  };

  return (
    <div
      className={`menu-lateral flex flex-col gap-20 ${isCollapsed ? 'collapsed' : ''}`}
    >
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('home')}
      >
        {isCollapsed ? (
          <div className="icono-menu-container">
            <div className="round-icon">{t('Home').charAt(0)}</div>
          </div>
        ) : (
          <span className="button-content">{t('Home')}</span>
        )}
      </div>
      <div
        className={`menu-section registrar ${isCollapsed ? 'icon-only' : ''}`}
      >
        {isCollapsed ? (
          <>
            <div className="icono-menu-container" onClick={toggleRegistrar}>
              <div className="round-icon">{t('Register').charAt(0)}</div>
              <span className={showRegistrar ? 'icon-up' : 'icon-down'}>
                <img src={ArrowDown} alt={t('ExpandCollapse')} />
              </span>
            </div>
            {showRegistrar && (
              <div className="registrar-options-collapsed">
                <div
                  className={`button-k menu-option ${activeButton === 'order' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('order')}
                >
                  <img
                    src={
                      activeButton === 'order'
                        ? PendingOrders
                        : PendingOrdersActive
                    }
                    className="icono-documento"
                    alt="Registrar por documento"
                    style={{ width: '16px' }}
                  />
                </div>
                <div
                  className={`button-k menu-option ${activeButton === 'form' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('form')}
                >
                  <img
                    src={activeButton === 'form' ? OrderFormActive : OrderForm}
                    className="icono-documento"
                    alt="Registrar por formulario"
                    style={{ width: '16px' }}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="menu-title" onClick={toggleRegistrar}>
              {t('Register')}
              <span className={showRegistrar ? 'icon-up' : 'icon-down'}>
                <img src={ArrowDown} alt="Expandir/Cerrar" />
              </span>
            </div>
            {showRegistrar && (
              <div className="registrar">
                <div
                  className={`button-k menu-option ${activeButton === 'order' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('order')}
                >
                  <span className="button-content">
                    <img
                      src={
                        activeButton === 'order'
                          ? PendingOrders
                          : PendingOrdersActive
                      }
                      className="icono-documento"
                      alt="Registrar por documento"
                      style={{ width: '16px' }}
                    />
                    {t('RegisterByDocument')}
                  </span>
                </div>
                <div
                  className={`button-k menu-option ${activeButton === 'form' ? 'selected' : ''}`}
                  onClick={() => handleButtonClick('form')}
                >
                  <span className="button-content">
                    <img
                      src={
                        activeButton === 'form' ? OrderFormActive : OrderForm
                      }
                      alt="Registrar por formulario"
                      style={{ width: '16px' }}
                    />
                    {t('RegisterByForm')}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('search')}
      >
        {isCollapsed ? (
          <div className="round-icon">{t('Search').charAt(0)}</div>
        ) : (
          <span className="button-content">{t('Search')}</span>
        )}
      </div>
      <div
        className={`menu-section ver-solicitudes ${isCollapsed ? 'icon-only' : ''}`}
      >
        {isCollapsed ? (
          <div className="icono-menu-container">
            <div className="round-icon">
              {getInitials(t('ViewCounterpartyRequests'))}
            </div>
            <span className="icon-down">
              <img src={ArrowDown} alt="Expandir/Cerrar" />
            </span>
          </div>
        ) : (
          <div className="menu-title">
            {t('ViewCounterpartyRequests')}
            <span className="arrow-img">
              <img src={ArrowDown} />
            </span>
          </div>
        )}
      </div>
      <div
        className={`menu-item ${isCollapsed ? 'icon-only' : ''}`}
        onClick={() => handleButtonClick('reports')}
      >
        {isCollapsed ? (
          <div className="round-icon">{t('Reports').charAt(0)}</div>
        ) : (
          <span className="button-content">{t('Reports')}</span>
        )}
      </div>
    </div>
  );
};

export default SideMenuCompany;
